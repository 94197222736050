<template>
  <el-container class="camadd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
       <el-breadcrumb-item>处理方式列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.dispose_id">编辑处理方式</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增处理方式</el-breadcrumb-item>
    </el-breadcrumb>
      <div> 
        <el-button class="back_btn"  size="medium"  plain  @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="">
      <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
        
       <el-col :span="11" class="tl" >
           <el-form-item label="处理方式名称："  class="w" label-width="28%"  prop='dispose_name'  :rules="[{required: true,message: '请输入处理方式名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
          <el-input v-model="form.dispose_name" size="medium"  clearable></el-input>
        </el-form-item>
        </el-col>
      </el-form>
      <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="onSubmit('form')">提交</el-button>
        </el-col>
    
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import {session} from '../../../util/util'
export default {
  data () {
    return {
      form:{
          dispose_id:'',//处理方式ID 更新操作必传,新增不需要传
          dispose_name:''
      }
    };
  },
  filters: {},
  created () {
       this.form.dispose_id =session.get('params').id?String(session.get('params').id):'';
        if(this.form.dispose_id){ this.getDate()}//编辑获取回显信息
   },
  methods: {
      getDate(){
          axios.get("/pc/patrol-dispose/single",{dispose_id:String(this.form.dispose_id)}).then(v => {
           this.form.dispose_name=v.data.patrol_dispose.dispose_name
          });
      },
        onSubmit (form) {
          
      this.$refs[form].validate((valid) => {
        if (valid) {
            //  this.form.dispose_id=String(this.form.dispose_id)
          axios.get("/pc/patrol-dispose/exist", this.form).then(v => {//验证查询监管分类是否存在
           if(this.form.dispose_id){
                
               axios.put("/pc/patrol-dispose/update",this.form).then(response => {
              this.$router.back();
            })}else{
              axios.post("/pc/patrol-dispose/save",this.form).then(response => {
              this.$router.back();
            })
           }
           
          });
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.camadd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  .form-container {
    margin-top: 20px;
    padding-right: 35px;
    /deep/ .el-form-item__content {
      width: 50%;
    }
  }
  /deep/ .el-radio__label {
    display: none !important;
  }
  /deep/ .el-dialog__body {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
    border-color: #e4e7ed;
    color: #606266;
    cursor: not-allowed;
  }
  /deep/ .el-dialog {
    width: 60% !important;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /* .el-form-item--medium .el-form-item__content{width: 80%} */
}
</style>
